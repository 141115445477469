import { useCallback } from 'react'
import { useUser } from './useUser'
import { doesUserHaveRole, isUserAllowedFeatureTier } from '../utils'
import { UserFeatureTier, UserRole } from '../types'

type DoesUserHaveAccessOpts = {
  featureTiers?: UserFeatureTier[]
  roles?: UserRole[]
}

export const useUserAccess = () => {
  const { data: user } = useUser()

  const validateUserAccess = useCallback(
    ({ featureTiers, roles }: DoesUserHaveAccessOpts) => {
      if (
        Array.isArray(featureTiers) &&
        featureTiers.length > 0 &&
        !isUserAllowedFeatureTier(user, featureTiers)
      )
        return false

      if (
        Array.isArray(roles) &&
        roles.length > 0 &&
        roles &&
        !doesUserHaveRole(user, roles)
      )
        return false

      return true
    },
    [user]
  )

  return {
    featureTier: user?.feature_tier,
    role: user?.role,
    validateUserAccess,
  }
}
